import { useEffect, useState } from 'react'

export function useIsMobile(maxWidth = 720) {
  const isWindowClient = typeof window !== 'undefined'

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (isWindowClient) {
      setIsMobile(window.innerWidth < maxWidth)
    }
  }, [isWindowClient, maxWidth])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < maxWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [maxWidth])

  return isMobile
}
