import { useMutation } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useEffect, useState } from 'react'

import { api } from '~/clients/api'
import { Button } from '~/components/button'
import { Text } from '~/components/text'
import { env } from '~/env.mjs'
import { removeAccessToken, setAccessToken } from '~/features/auth/use-user'
import { UsernamePasswordForm } from '~/features/auth/username-password-form'
import { useIsMobile } from '~/hooks/use-is-mobile'

export default function Home() {
  const [signInMethod, setSignInMethod] = useState<'sso' | 'username'>('sso')
  const router = useRouter()
  const isMobile = useIsMobile()
  const redirectUrl = isMobile ? '/scan-offer' : '/waiting-room'

  const ssoAuth = useMutation({
    mutationFn: async (code: string) =>
      api.authentication.authenticate(
        { 'x-client-id': env.NEXT_PUBLIC_X_CLIENT_ID },
        { code, redirectUrl: window.location.origin },
      ),
    onSuccess: (response) => {
      setAccessToken(response.data.accessToken)
      window.sessionStorage.setItem('ssoCode', response.data.code)
      router.push(redirectUrl)
    },
    onError: () => {
      removeAccessToken()
      window.sessionStorage.removeItem('ssoCode')
      router.replace('/', { query: {} })
    },
  })

  // coming back from SSO
  useEffect(() => {
    const code = router.query.code
    if (code && typeof code === 'string') {
      ssoAuth.mutate(code)
    }
  }, [router.query.code])

  // coming back from username/password login
  useEffect(() => {
    if (window.sessionStorage.getItem('ssoCode')) {
      router.push(redirectUrl)
    }
  }, [])

  const ssoAuthUrl = `https://login.microsoftonline.com/${env.NEXT_PUBLIC_SSO_APP_ID}/oauth2/v2.0/authorize?client_id=${env.NEXT_PUBLIC_SSO_CLIENT_ID}&response_type=code&redirect_uri=${env.NEXT_PUBLIC_SSO_REDIRECT_URI}&response_mode=query&scope=${env.NEXT_PUBLIC_SSO_SCOPES}`

  return (
    <main className="flex h-screen w-screen font-ikea">
      {!isMobile && (
        <div className="flex w-1/2 items-center bg-coworker-blue">
          <img
            src="/auth-background.webp"
            className="size-full bg-cover object-cover"
            alt=""
          />
        </div>
      )}
      <div className="flex flex-col justify-center gap-8 p-8 md:w-1/2 md:p-22">
        <img src="/ikea-logo.svg" className="w-[88px]" alt="" />
        <div className="flex grow items-center">
          {signInMethod === 'username' && (
            <UsernamePasswordForm onCancel={() => setSignInMethod('sso')} />
          )}
          {signInMethod === 'sso' && (
            <div className="flex flex-col items-start gap-8">
              <Text tagName="h1" headingSize="xl">
                Hej! Welcome
                <br />
                to As-Is Online.
              </Text>
              <Button
                loading={ssoAuth.isPending}
                type="primary"
                href={ssoAuthUrl}
              >
                Single sign-on
              </Button>
              {ssoAuth.isError ? (
                <Text tagName="p" className="!text-lingoberry-2500">
                  {`There was an error signing in. Error: "${ssoAuth.error}".`}
                  <br />
                  <a
                    className="font-bold underline"
                    href="https://jira.digital.ingka.com/servicedesk/customer/portal/16/group/49"
                  >
                    Click here to open a support ticket, so we can investigate
                    it.
                  </a>
                </Text>
              ) : (
                // here to hold space for the error message and avoid a big content shift
                <Text tagName="p">
                  <br />
                  <br />
                </Text>
              )}
            </div>
          )}
        </div>
        <div className="flex items-center">
          <Text tagName="p" bodySize="s" className="mr-2">
            {`Don't have SSO?`}
          </Text>
          <Button
            type="plain"
            size="small"
            className="!font-normal underline"
            onClick={() => setSignInMethod('username')}
          >
            Sign in with username
          </Button>
        </div>
      </div>
    </main>
  )
}

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['translation'])),
    },
  }
}
